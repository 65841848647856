<template>
  <div>
    <div class="basketf">
      <div class="top-tit">篮子策略</div>
      <div class="f-con">
        <div class="f-img tleft">
          <img :src="require('@/assets/images/basket1.png')" alt="" />
        </div>
        <div class="f-w">
          <div class="blueline"></div>
          <div class="word">
            <p>无需掌握计算机编程语言</p>
            <p>就能轻松完成策略编写</p>
          </div>
        </div>
      </div>
    </div>
    <div class="baskets">
      <div class="w-con">
        <div class="ws">
          <p>
            “篮子策略”是为了分散投资风险而开发的多只股票交易测试功能，可以轻松满足
          </p>
          <p>
            交易品种和标的较多的超级散户、机构投资者需求。同时，可视化界面还可以弥补普通交易者的
          </p>
          <p>计算机算法空白，让普通人做操盘手的愿望不再遥不可及。</p>
        </div>
        <div class="wbtn">可视化界面</div>
      </div>

      <div class="bg-con">
        <img :src="require('@/assets/images/basketb1.jpg')" alt="" />
      </div>
    </div>
    <div class="basketf">
      <!-- <div class="top-tit">篮子策略</div> -->
      <div class="f-con">
        <div class="f-w">
          <div class="blueline"></div>
          <div class="word">
            <p class="big-t">策略编辑可以简单，可以普及</p>
            <p class="lit-t">不再是计算机高端人才的自留地</p>
          </div>
        </div>
        <div class="f-img tright">
          <img :src="require('@/assets/images/basket2.png')" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="less">
.basketf {
  width: 100%;
  max-width: 1200px;
  min-width: 1000px;
  padding-top: 55px;
  margin: 0 auto;
  .top-tit {
    width: 100%;
    font-size: 25px;
    text-align: left;
  }
  .f-con {
    width: 100%;
    display: flex;
    padding-top: 85px;
    align-items: center;
    .f-img {
      width: 70%;
      img {
        max-width: 100%;
      }
    }
    .f-w {
      width: 30%;
      text-align: left;
      padding-bottom: 100px;
      .blueline {
        width: 60px;
        height: 6px;
        border-radius: 3px;
        background-color: #5ca1ff;
        margin-bottom: 15px;
      }
      .word {
        font-size: 18px;
        line-height: 40px;
      }
    }
  }
}
.baskets {
  width: 100%;
  min-width: 1000px;
  position: relative;
  .bg-con {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .w-con {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    .ws {
      color: #ffffff;
      line-height: 36px;
      font-size: 20px;
      letter-spacing: 3px;
      padding-bottom: 20px;
    }
    .wbtn {
      cursor:pointer;
      padding: 4px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ffffff;
    }
  }
}
.tright {
  text-align: right;
}
.tleft {
  text-align: left;
}
.big-t {
  font-size: 22px;
}
.lit-t {
  font-size: 16px;
  color: #999999;
  letter-spacing: 3px;
}
</style>